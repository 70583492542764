
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store/index";

// Models
import {AppCommonTrainingCategory} from "@/models/app/common/training-category";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import AdministratorNavigationVue from "@/components/administrator/navigation.vue";
import AdministratorPlayersProfileNavigationVue from "@/components/administrator/players/profile/navigation.vue";
import AdministratorPlayersProfileTrainingsFilterIndexVue from "@/views/app/administrator/players/profile/trainings/filter/index.vue";
import AdministratorPlayersProfileTrainingsListIndexVue from "@/views/app/administrator/players/profile/trainings/list/index.vue";

// Services
import {getRequest} from "@/services/api/request";

@Options({
  components: {
    ElRow,
    ElCol,
    AdministratorNavigationVue,
    AdministratorPlayersProfileNavigationVue,
    AdministratorPlayersProfileTrainingsFilterIndexVue,
    AdministratorPlayersProfileTrainingsListIndexVue
  },
})
export default class AdministratorPlayersProfileTrainingsIndexVue extends Vue {
  isLoading: boolean | null = false;

  trainingCategories: Array<AppCommonTrainingCategory> = [];

  $refs!: {
    AdministratorPlayersProfileTrainingsListIndexVue: any;
  };

  getTrainingsByFilter(formData): void {
    this.$refs.AdministratorPlayersProfileTrainingsListIndexVue.getTrainings(formData);
  }

  async getTrainingCategories(): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/common/utility/training-categories`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.trainingCategories = r.data;
      }
    });
    this.isLoading = false;
  }

  async created(): Promise<void> {
    await this.getTrainingCategories();
  }
}
